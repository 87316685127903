import { useTranslation } from 'react-i18next';
import { Surface, Txt } from '@vst/beam';

import { useScreenResolution } from '@mfe/shared/util';

import { CartProps } from './types';
import { getPriceAndDiscount } from '../addons-grid/utils';
import { EmptyCart } from './empty-cart';
import { CartAddonName } from './cart-addon-name';
import { TermsAndSubscribeButton } from './terms-and-subscribe';
import styles from './styles.module.scss';

export const AddonCart: React.FC<CartProps> = (props) => {
  if (!props.addon.is_grouped_product) {
    return <PopulatedCart {...props} />;
  }

  return props.selectedEasyCareProduct ? (
    <PopulatedCart {...props} addon={props.selectedEasyCareProduct} />
  ) : (
    <EmptyCart {...props} />
  );
};

const PopulatedCart: React.FC<CartProps> = ({
  addon,
  submitButtonLabel,
  isSubmitDisabled,
  handleClick,
}) => {
  const { t } = useTranslation('ShopAddons');

  const { name, kind } = addon;
  const {
    price = undefined,
    oldPrice = undefined,
    totalPromotionDuration = undefined,
    promotions = [],
  } = getPriceAndDiscount(addon);

  const { isLarge, isExtraLarge } = useScreenResolution();
  const isDesktop = isLarge || isExtraLarge;

  const hasPromotions = promotions.length > 0;
  const priceColor = hasPromotions ? 'success' : 'regular';
  const hasActivePromotions =
    oldPrice !== undefined && totalPromotionDuration !== undefined;

  return (
    <div className={styles['cart']}>
      <Surface
        radius="16px"
        p={isDesktop ? '24px' : undefined}
        data-cy="cart-content"
      >
        <div className={styles['vertical-spacing']}>
          <Txt variant="bodyLargeBold" color="subtle">
            {t('cart.title')}
          </Txt>
          <div className={styles['content']}>
            <CartAddonName name={name} kind={kind} />
            <div className={styles['subscription-price']}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Txt
                  variant="bodySmallRegular"
                  color="regular"
                  style={{ alignSelf: 'self-start' }}
                >
                  {t('cart.subscription')}
                </Txt>

                <Txt
                  variant="bodySmallBold"
                  color={priceColor}
                  style={{ textAlign: 'right' }}
                >
                  {price === 0
                    ? t('cart.free')
                    : t('cart.pricePerMonth', { price })}
                  {hasActivePromotions && (
                    <Txt component="p" variant="smallItalic" color="subtle">
                      {t('cart.priceAfterDiscount', {
                        price: oldPrice,
                        duration: totalPromotionDuration,
                      })}
                    </Txt>
                  )}
                </Txt>
              </div>
            </div>
            <div className={styles['total']}>
              <Txt variant="heading6" color="regular">
                {t('cart.total')}
              </Txt>
              <Txt
                variant="heading6"
                color="regular"
                style={{ textAlign: 'right' }}
              >
                {price === 0
                  ? t('cart.free')
                  : t('cart.pricePerMonth', { price })}
                {hasActivePromotions && (
                  <Txt component="p" variant="smallItalic" color="subtle">
                    {t('cart.priceAfterDiscount', {
                      price: oldPrice,
                      duration: totalPromotionDuration,
                    })}
                  </Txt>
                )}
                <Txt component="p" variant="smallRegular" color="regular">
                  {t('cart.taxes')}
                </Txt>
              </Txt>
            </div>
          </div>
          <TermsAndSubscribeButton
            kind={kind}
            handleClick={handleClick}
            isSubmitDisabled={isSubmitDisabled}
            submitButtonLabel={submitButtonLabel}
          />
        </div>
      </Surface>
    </div>
  );
};
