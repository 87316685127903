import { Txt } from '@vst/beam';

import { AddonIcon } from '@mfe/shared/components';

import styles from './styles.module.scss';

type AddonProps = {
  name: string;
  kind: string;
};

export const CartAddonName: React.FC<AddonProps> = ({ name, kind }) => {
  return (
    <div className={styles['addon']}>
      <AddonIcon kind={kind} className={styles['icon']} />
      <Txt variant="paragraphBold" color="regular">
        {name}
      </Txt>
    </div>
  );
};
