import { HeaderWithBackNavigation } from '@mfe/shared/components';
import styles from './styles.module.scss';
import useNavigate from '@mfe/services/navigation';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetEasyCareSelection,
  selectAddOns,
  selectCurrentEasyCareProduct,
  selectCurrentShopAddon,
  setMissingSelection,
} from '@mfe/to-be-migrated/redux/addOns';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Spinner, Txt } from '@vst/beam';

import { AddonCart, CollapsibleCart } from '../cart';
import {
  MarketingCharacteristics,
  getCharacteristicValue,
  useScreenResolution,
} from '@mfe/shared/util';

import {
  AEMContentProvider,
  AemFeature,
  createAemUrl,
} from '@mfe/shared/components';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { TermsAndConditions } from '../components/TermsAndConditions';
import { EasyCareSelector } from './easy-care-selector';
import { ProductType } from '@mfe/shared/schema-types';
import { AddonAemUnavailable } from '../components/addon-aem-unavailable';
import { useShopRedirectIfNoSelection } from '../hooks/use-redirect-to-shop';

export const GroupedCheckout = ({
  handleSubmit,
}: {
  handleSubmit: () => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('ShopAddons');
  const { goBack } = useNavigate();

  const missingSelectionRef = useRef<HTMLDivElement>(null);

  const [aemFragmentLoading, setAEMFragmentLoading] = useState(true);
  const [aemFragmentError, setAEMFragmentError] = useState(false);

  const { isMissingEasyCareSelection } = useSelector(selectAddOns);

  const { isLarge, isExtraLarge } = useScreenResolution();
  const isDesktop = isLarge || isExtraLarge;
  const isMobile = !isDesktop;

  const currentShopAddon = useSelector(selectCurrentShopAddon);
  const products = currentShopAddon?.package_types?.[0].product_types || [];
  const chosenEasyCareProduct = useSelector(selectCurrentEasyCareProduct);

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const { aemBaseUrl } = useSelector(selectConfig);

  const aemURL = createAemUrl({
    baseUrl: aemBaseUrl,
    locale: userLocale,
    feature: AemFeature.GROUPED_EASY_CARE,
    accountType,
  });

  useEffect(() => {
    return () => {
      dispatch(resetEasyCareSelection());
      dispatch(setMissingSelection(false));
    };
  }, []);

  useShopRedirectIfNoSelection();

  const handlePrimaryAction = () => {
    if (chosenEasyCareProduct?.id) {
      handleSubmit();
    } else {
      dispatch(setMissingSelection(true));

      const element = missingSelectionRef.current;
      if (element && isMissingEasyCareSelection) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  if (!currentShopAddon) {
    return null;
  }

  const disclosure =
    getGroupedProductDisclosure(products as ProductType[]) ?? '';

  return (
    <>
      {isMobile && (
        <CollapsibleCart
          handleClick={handlePrimaryAction}
          addon={currentShopAddon}
          submitButtonLabel={t('subscribeNow')}
          selectedEasyCareProduct={chosenEasyCareProduct as ProductType}
        />
      )}
      <div className={styles['wrapper']}>
        <HeaderWithBackNavigation
          handleBackNavigation={() => {
            goBack();
          }}
          title={currentShopAddon.name}
          goBackLabel={t('allAddOns')}
          additionalClassName={styles['header']}
        />

        <div className={styles['page-content']}>
          <div className={styles['left-card']}>
            <div className={styles['title-and-selector']}>
              <div className={styles['title-subtitle']}>
                <Txt variant="heading4" mb="16px">
                  {t('groupedEasyCare.cardTitle')}
                </Txt>
                <Txt component="span" variant="paragraphRegular">
                  {t('groupedEasyCare.cardDescription')}
                </Txt>
              </div>
              <EasyCareSelector missingSelectionRef={missingSelectionRef} />
            </div>
            <div className={styles['aem-fragment']}>
              {aemFragmentLoading && (
                <div style={{ padding: '32px' }}>
                  <Spinner label={t('cart.aemFragment.loading')} />
                </div>
              )}
              {aemFragmentError && !aemFragmentLoading ? (
                <div className={styles['addon-unavailable-wrapper']}>
                  <AddonAemUnavailable />
                </div>
              ) : (
                <AEMContentProvider
                  id={styles['aem-fragment-container']}
                  style={{ display: aemFragmentLoading ? 'none' : 'block' }}
                  src={aemURL}
                  onLoad={() => setAEMFragmentLoading(false)}
                  onError={() => {
                    setAEMFragmentError(true);
                  }}
                />
              )}
            </div>
            <div
              style={{ display: aemFragmentLoading ? 'none' : 'block' }}
              className={styles['card-footer']}
            >
              <TermsAndConditions content={disclosure} />
              <div className={styles['submit-container']}>
                <Button onClick={handlePrimaryAction} buttonSize="large">
                  {t('subscribeNow')}
                </Button>
              </div>
            </div>
          </div>
          {isDesktop && (
            <AddonCart
              handleClick={handlePrimaryAction}
              addon={currentShopAddon}
              submitButtonLabel={t('subscribeNow')}
              selectedEasyCareProduct={chosenEasyCareProduct as ProductType}
            />
          )}
        </div>
      </div>
    </>
  );
};

function getGroupedProductDisclosure(products: ProductType[]) {
  const disclosures = products?.map((product: ProductType | null) => {
    const description = getCharacteristicValue(
      product?.marketing_copy?.translations?.[0]?.characteristics ?? [],
      MarketingCharacteristics.DISCLOSURE
    );
    return description;
  });

  return disclosures[0];
}
