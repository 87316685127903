import { all } from 'redux-saga/effects';

import { watchAlerts } from './alerts';
import { watchAnalytics } from './analytics';
import { watchAuth, watchUser } from './auth';
import { watchUsernameMutations } from './deleteUsername';
import { watchBillingInfo } from './billingInfo';
import { watchEvents } from './events';
import { watchLocale } from './locale';
import { watchPayments } from './payments';
import { watchHistoricalUsageInfo } from './usageHistory';
import { watchRaygun } from './raygun';
import { watchRegistration, watchRegistrationAnalytics } from './registration';
import { watchScrubbedAddress } from './scrubbedAddress';
import { watchStreamOn } from './streamOn';
import { watchStreamOnPromo } from './streamOnPromo';
import { watchUserInfo } from './userInfo';
import { watchUtils as watchOldUtils } from './utils';
import { watchUtils } from '@mfe/shared/redux/utils';
import { watchConfig } from '@mfe/shared/redux/config';
import { watchUpdateAddress } from './updateAddress';
import { watchCrossPlatformCommunication } from '@mfe/shared/cross-platform-events';
import { watchPushNotification } from './push-notifications';
import { watchBuyMore } from './buyMore';
import { watchBuyMoreHistory } from './buyMoreHistory';
import { watchChangePlanOffers } from './changePlan';
import { watchScheduleInstallation } from './scheduleInstallation/scheduleInstallationSagas';
import { watchUpdateBillCycle } from './updateBillCycle/sagas';
import { watchPortfolio } from './portfolio/portfolioSagas';
import { watchNetwork } from './network';
import { watchPlan } from './plan/planSagas';
import { watchBroadbandLabelAttributes } from './broadbandLabel/broadbandLabelSagas';
import { watchWorkOrders } from './workOrders/workOrdersSagas';
import { watchAddOns } from './addOns/addOnsSagas';

function* rootSaga() {
  yield all([
    watchOldUtils(),
    watchUtils(),
    watchCrossPlatformCommunication(),
    watchConfig(),
    watchUpdateAddress(),
    watchEvents(),
    watchStreamOn(),
    watchAuth(),
    watchAnalytics(),
    watchUser(),
    watchUsernameMutations(),
    watchLocale(),
    watchScrubbedAddress(),
    watchRegistration(),
    watchRegistrationAnalytics(),
    watchRaygun(),
    watchUserInfo(),
    watchBuyMoreHistory(),
    watchHistoricalUsageInfo(),
    watchStreamOnPromo(),
    watchBillingInfo(),
    watchPayments(),
    watchBuyMore(),
    watchAlerts(),
    watchChangePlanOffers(),
    watchPushNotification(),
    watchScheduleInstallation(),
    watchUpdateBillCycle(),
    watchPortfolio(),
    watchNetwork(),
    watchPlan(),
    watchBroadbandLabelAttributes(),
    watchWorkOrders(),
    watchAddOns(),
  ]);
}

export default rootSaga;
