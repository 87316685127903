import { useSelector } from 'react-redux';
import OneStepCheckout from '../one-step-checkout';
import { selectCurrentShopAddon } from '@mfe/to-be-migrated/redux/addOns';
import { GroupedCheckout } from '../grouped-checkout';

enum AddOnCheckoutSteps {
  CHECKOUT = 'CHECKOUT',
  CONFIGURE_VOICE = 'CONFIGURE_VOICE',
}

export const OneStepCheckoutManager = ({
  setStep,
  handleSubmit,
}: {
  setStep: (step: AddOnCheckoutSteps) => void;
  handleSubmit: () => void;
}) => {
  const currentShopAddon = useSelector(selectCurrentShopAddon);

  const isGroupedAddon = currentShopAddon?.is_grouped_product;

  return isGroupedAddon ? (
    <GroupedCheckout handleSubmit={handleSubmit} />
  ) : (
    <OneStepCheckout
      handleSubmit={handleSubmit}
      goToConfigure={() => setStep(AddOnCheckoutSteps.CONFIGURE_VOICE)}
    />
  );
};
