import { Button, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import { TermsAndConditions } from '../components';
import { getTermsAndConditions } from '../components/utils';

type TermsAndSubscribeButtonProps = {
  kind: string;
  isSubmitDisabled?: boolean;
  submitButtonLabel: string;
  handleClick: () => void;
};

export const TermsAndSubscribeButton = ({
  kind,
  handleClick,
  isSubmitDisabled,
  submitButtonLabel,
}: TermsAndSubscribeButtonProps) => {
  const { t } = useTranslation('ShopAddons');

  const termsAndConditionsContent = t(
    `termsAndConditions.${getTermsAndConditions(kind)}`
  );
  return (
    <>
      <TermsAndConditions content={termsAndConditionsContent} />
      <Button width="100%" onClick={handleClick} disabled={isSubmitDisabled}>
        {submitButtonLabel}
      </Button>
      <Txt component="p" variant="tinyRegular" color="subtle">
        {t('cart.taxesDisclaimer')}
      </Txt>
    </>
  );
};
