import { ProductType, ProductTypeDiscount } from '@mfe/shared/schema-types';

export function getShortenedZipCode(zipCode: string) {
  return zipCode.split('-')[0];
}

export const sortProductTypesByDisplayOrder = (
  a: ProductType | null,
  b: ProductType | null
) => {
  if (!a || !b) {
    return 0;
  }
  const aOrder = a.marketing_copy.ui_behaviors.characteristics?.find(
    (characteristic) => characteristic.name === 'DISPLAY_ORDER'
  )?.value;
  const bOrder = b.marketing_copy.ui_behaviors.characteristics?.find(
    (characteristic) => characteristic.name === 'DISPLAY_ORDER'
  )?.value;

  if (aOrder && bOrder) {
    if (aOrder === bOrder) {
      return 0;
    }

    return aOrder > bOrder ? 1 : -1;
  }

  if (aOrder) {
    return 1;
  }

  if (bOrder) {
    return -1;
  }

  return 0;
};

export function sortByCheapest(productTypes: ProductType[]): ProductType[] {
  if (productTypes.some((product) => !product.prices)) {
    return productTypes;
  }

  return productTypes.slice().sort((a: ProductType, b: ProductType) => {
    const priceA: number = a?.prices?.[0].amount.value ?? 0;
    const priceB: number = b?.prices?.[0].amount.value ?? 0;
    return priceA - priceB;
  });
}

export function matchAddonWithFreeDiscount(
  productType: ProductType,
  rootProductType: ProductType
) {
  /*
  According to the catalog EasyCare products will be free for the first 3 months
  Addon discounts match addon prices in value, but discount amounts are negative

  TODO: Update logic here if OFM provides the discounts on each product type in the group
  */
  return rootProductType?.discounts?.itemized_discounts?.find((discount) => {
    return discount?.amount?.value && productType?.prices?.[0]?.amount?.value
      ? discount?.amount?.value + productType?.prices?.[0]?.amount?.value === 0
      : undefined;
  });
}

export function addDiscountToProductType(
  productType: ProductType,
  discount: ProductTypeDiscount | null | undefined
) {
  if (!discount) return productType;

  return {
    ...productType,
    discounts: {
      itemized_discounts: [discount],
      total_discounts: { ...discount },
    },
  };
}
