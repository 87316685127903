import { all, call, select, takeLatest, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ApolloQueryResult } from '@apollo/client';

import {
  FetchWithErrorsMutation,
  FetchWithErrorsQuery,
  graphqlMutationWithErrors,
  graphqlQueryWithErrors,
} from '@mfe/shared/redux/graphql';
import {
  Address,
  AvailablePhoneNumber,
  ConfigureAddOnFailureReason,
  ConfigureAddonInput,
  GetCurrentAddOnsPayload,
  PurchaseAddOnInput,
  Query,
  RemoveAddOnInput,
  SendSurveyResultsToQualtricsInput,
} from '@mfe/shared/schema-types';
import {
  ScrubAddressProcessStatus,
  ScrubAddressRecommendation,
} from '@mfe/shared/graphql/PAL/types';

import {
  AddOnsState,
  setError,
  getAvailableNumbers,
  reserveSelectedNumber,
  setAvailableNumbers,
  setAvailableNumbersError,
  setNumberReservationSuccess,
  setNumberReservationError,
  scrubEmergencyAddress,
  resetE911ScrubbedAddress,
  setE911Error,
  setE911ScrubbedAddress,
  setShowModal,
  purchaseAddOn,
  getAddOnOffers,
  setAddOnOffers,
  setAddOnOffersError,
  setPurchaseAddOnError,
  setPurchaseAddOnSuccess,
  setAddOnOrderId,
  setConfigureAddonSuccess,
  setConfigureAddonError,
  getCurrentAddOns,
  setCurrentAddOns,
  refetchCurrentAddOns,
  refetchAddOnOffers,
  sendSurveyResults,
  removeAddOn,
  setLoading,
  selectAddOns,
  setVoiceEquipmentError,
} from './addOnsSlice';
import { selectUserInfo } from '../userInfo';
import { waitForUserInfo } from '../utils/utilsSagas';
import {
  GET_AVAILABLE_PHONE_NUMBERS,
  GET_ADDON_OFFERS,
  PURCHASE_ADD_ON,
  RESERVE_PHONE_NUMBER,
  GET_SCRUBBED_ADDRESS,
  CONFIGURE_ADDON,
  GET_CURRENT_ADD_ONS,
  SEND_SURVEY_RESULTS_TO_QUALTRICS,
  REMOVE_ADDON,
} from './queriesAndMutations';
import { clearError } from '../error';
import { getShortenedZipCode, sortProductTypesByDisplayOrder } from './utils';
import { ModalTypeEnum } from '../../libUtils';
import { selectConfig } from '@mfe/shared/redux/config';

function* fetchAddOnOffers(action: any) {
  const { showGroupedEasyCare } = yield select(selectConfig);

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_ADDON_OFFERS,
    variables: { showGroupedEasyCare },
    fetchPolicy: action.payload ? 'network-only' : 'cache-first',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setAddOnOffersError(runtimeError ?? errors));
    return;
  }

  const addons = data?.getAddOnOffers;

  if (addons) {
    const sortedRootOffers = addons
      .slice()
      .sort(sortProductTypesByDisplayOrder);

    const sortedOffers = sortedRootOffers.map((addon) => {
      if (
        addon.is_grouped_product &&
        addon?.package_types?.[0]?.product_types
      ) {
        const sortedSubproducts = addon.package_types[0].product_types
          .slice()
          .sort(sortProductTypesByDisplayOrder);

        return {
          ...addon,
          package_types: [
            { ...addon.package_types[0], product_types: sortedSubproducts },
            ...addon.package_types.slice(1),
          ],
        };
      }

      return addon;
    });

    yield put(setAddOnOffers(sortedOffers));
  }
}

function* fetchAvailablePhoneNumbers(action: PayloadAction<string>) {
  yield call(waitForUserInfo);
  const { userInfo } = yield select(selectUserInfo);

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_AVAILABLE_PHONE_NUMBERS,
    variables: {
      input: {
        postal_code: getShortenedZipCode(
          action.payload || userInfo.address.service?.postalCode
        ),
      },
    },
    fetchPolicy: 'no-cache',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setAvailableNumbersError(runtimeError ?? errors));
    return;
  }

  if (data?.getAvailableTelephoneNumbers) {
    yield put(
      setAvailableNumbers(
        data.getAvailableTelephoneNumbers as AvailablePhoneNumber[]
      )
    );
  }
}

export function* sendSurveyResultsToQualtrics(
  action: PayloadAction<SendSurveyResultsToQualtricsInput>
) {
  yield call(graphqlMutationWithErrors, {
    mutation: SEND_SURVEY_RESULTS_TO_QUALTRICS,
    variables: {
      input: {
        embeddedData: {
          billingAccountNumber:
            action.payload.embeddedData.billingAccountNumber,
          addOn: action.payload.embeddedData.addOn,
        },
        firstQuestionAnswer: action.payload.firstQuestionAnswer,
        secondQuestionAnswer: action.payload.secondQuestionAnswer ?? '',
      },
    },
    fetchPolicy: 'no-cache',
  });
}

function* removeAddon(action: PayloadAction<RemoveAddOnInput>) {
  try {
    const apiResponse: FetchWithErrorsMutation = yield call(
      graphqlMutationWithErrors,
      {
        mutation: REMOVE_ADDON,
        variables: { input: action.payload },
        fetchPolicy: 'no-cache',
      }
    );

    const { data, errors, runtimeError } = apiResponse;
    if (data?.removeAddOn?.message) {
      yield put(setShowModal(ModalTypeEnum.CancelSubscriptionError));
      yield put(setLoading(false));
    }
    if (runtimeError || errors) {
      yield put(setShowModal(ModalTypeEnum.CancelSubscriptionError));
      yield put(setLoading(false));
    }

    if (data?.removeAddOn?.orderId) {
      yield put(refetchCurrentAddOns(data.removeAddOn.orderId));
    }
  } catch (e: any) {
    yield put(setShowModal(ModalTypeEnum.CancelSubscriptionError));
    yield put(setLoading(false));
  }
}

function* reserveNumber(action: PayloadAction<string>) {
  const apiResponse: FetchWithErrorsMutation = yield call(
    graphqlMutationWithErrors,
    {
      mutation: RESERVE_PHONE_NUMBER,
      variables: { input: { telephoneNumber: action.payload } },
      fetchPolicy: 'no-cache',
    }
  );

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setNumberReservationError(runtimeError ?? errors));
    return;
  }

  if (data?.reserveTelephoneNumber?.success) {
    yield put(setNumberReservationSuccess());
  }
}

export const parseE911ScrubbedAddress = (
  data: Partial<ApolloQueryResult<Query>['data']>,
  secondAddressLine: string
): AddOnsState['scrubbedE911Address'] | null => {
  const rawAddress = data.getScrubbedAddress;
  const firstAddressLine = rawAddress?.addressLines[0] ?? '';
  const municipality = rawAddress?.municipality ?? '';
  const region = rawAddress?.region ?? '';
  const postalCode = rawAddress?.postalCode ?? '';
  const countryName = rawAddress?.country ?? '';

  let formattedAddressLine1 = '';
  let formattedAddressLine2 = '';

  if (rawAddress?.processStatus !== ScrubAddressProcessStatus.Incorrect) {
    formattedAddressLine1 = `${firstAddressLine}, ${secondAddressLine} ${municipality}, `;
    formattedAddressLine2 = `${region} ${postalCode} ${countryName}`;
  }

  return {
    rawAddress,
    formattedAddress: { formattedAddressLine1, formattedAddressLine2 },
    processStatus: rawAddress?.processStatus as ScrubAddressProcessStatus,
    recommendation: rawAddress?.recommendation as ScrubAddressRecommendation,
  };
};

export function* scrubEmergencyAddressSaga({
  payload,
}: {
  type: string;
  payload: Address;
}) {
  yield put(clearError({}));

  payload.countryCode = payload.countryCode || 'US';

  try {
    const apiResponse: FetchWithErrorsQuery = yield call(
      graphqlQueryWithErrors,
      {
        query: GET_SCRUBBED_ADDRESS,
        variables: { address: payload },
      }
    );

    const { data, errors, runtimeError } = apiResponse;

    if (runtimeError || errors) {
      yield put(setE911Error(runtimeError ?? errors));
      yield put(setShowModal(ModalTypeEnum.AddressError));
      return;
    }
    let scrubbedE911Address;
    if (data?.getScrubbedAddress) {
      const secondAddressLine = payload.addressLines[1];
      scrubbedE911Address = parseE911ScrubbedAddress(data, secondAddressLine);
    }

    yield put(resetE911ScrubbedAddress());

    if (scrubbedE911Address) {
      yield put(setE911ScrubbedAddress({ scrubbedE911Address }));
    } else {
      yield put(
        setE911Error({
          operation: 'getScrubbedAddress',
          message: ['E911 getScrubbedAddress attempt failed.'],
        })
      );
    }
  } catch (error) {
    yield put(
      setE911Error({
        operation: 'getScrubbedAddress',
        message: ['E911 getScrubbedAddress attempt failed.'],
      })
    );
    yield put(resetE911ScrubbedAddress());
    yield put(setShowModal(ModalTypeEnum.AddressError));
  }
}

type PayloadType = {
  scrubbedE911Address: AddOnsState['scrubbedE911Address'];
};

function* checkScrubProcessStatus({
  payload,
}: {
  type: string;
  payload: PayloadType;
}) {
  const { processStatus, recommendation } = payload.scrubbedE911Address;

  if (
    processStatus === ScrubAddressProcessStatus.Verified ||
    (processStatus === ScrubAddressProcessStatus.Corrected &&
      recommendation === ScrubAddressRecommendation.RECOMMEND)
  ) {
    yield put(setShowModal(null));
    return;
  }

  if (
    processStatus === ScrubAddressProcessStatus.Incorrect &&
    recommendation === ScrubAddressRecommendation.REJECT
  ) {
    yield put(setShowModal(ModalTypeEnum.IncorrectAddress));
    return;
  }

  if (processStatus === ScrubAddressProcessStatus.Corrected) {
    return;
  }
  yield put(setShowModal(ModalTypeEnum.AddressError));
}

function* configureAddon(action: PayloadAction<ConfigureAddonInput>) {
  const input = action.payload;
  const apiResponse: FetchWithErrorsMutation = yield call(
    graphqlMutationWithErrors,
    {
      mutation: CONFIGURE_ADDON,
      variables: { input },
      fetchPolicy: 'no-cache',
    }
  );

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setConfigureAddonError(runtimeError ?? errors));
  }

  if (data?.configureAddon) {
    yield put(setConfigureAddonSuccess(data?.configureAddon?.success));

    if (
      !data?.configureAddon?.success &&
      data?.configureAddon?.message === ConfigureAddOnFailureReason.ExtendCart
    ) {
      yield put(setConfigureAddonError({ message: 'Unable to extend cart' }));
    }

    if (
      !data?.configureAddon?.success &&
      data?.configureAddon?.message ===
        ConfigureAddOnFailureReason.VoiceEquipment
    ) {
      yield put(setVoiceEquipmentError(true));
    }
  } else {
    yield put(setConfigureAddonError({ message: 'Unable to configure addon' }));
  }
}

function* purchaseAddOnSaga(action: PayloadAction<PurchaseAddOnInput>) {
  try {
    yield call(configureAddon, action);

    const { voiceEquipmentError, configureAddonSuccess } = yield select(
      selectAddOns
    );

    if (voiceEquipmentError || !configureAddonSuccess) {
      yield put(setPurchaseAddOnError('Unable to configure addon'));
      return;
    }

    const apiResponse: FetchWithErrorsMutation = yield call(
      graphqlMutationWithErrors,
      {
        mutation: PURCHASE_ADD_ON,
        variables: { input: action.payload },
      }
    );

    const { data } = apiResponse;

    if (data?.purchaseAddOn?.success) {
      yield put(setPurchaseAddOnSuccess(data.purchaseAddOn.success));
      yield put(setAddOnOrderId(data.purchaseAddOn.orderId ?? null));
    } else {
      yield put(setPurchaseAddOnError('Operation unsuccessful'));
    }
  } catch (error) {
    yield put(setPurchaseAddOnError(error));
  }
}

function* fetchCurrentAddons(action: any) {
  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_CURRENT_ADD_ONS,
    fetchPolicy: 'no-cache',
    variables: { input: { addOnOrderId: action.payload } },
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setError(runtimeError ?? errors));
    return;
  }

  if (data?.getCurrentAddOns) {
    yield put(
      setCurrentAddOns(data.getCurrentAddOns as GetCurrentAddOnsPayload[])
    );
  }
}

function* refetchCurrentAddOnsSaga(action: any) {
  yield call(fetchCurrentAddons, action);
}

function* refetchAddOnOffersSaga() {
  yield call(fetchAddOnOffers, {
    type: 'refetchAddOnOffers',
    payload: { refetchData: true },
  });
}

export function* watchAddOns() {
  yield all([
    takeLatest(getAvailableNumbers.type, fetchAvailablePhoneNumbers),
    takeLatest(reserveSelectedNumber.type, reserveNumber),
    takeLatest(scrubEmergencyAddress.type, scrubEmergencyAddressSaga),
    takeLatest(setE911ScrubbedAddress.type, checkScrubProcessStatus),
    takeLatest(purchaseAddOn.type, purchaseAddOnSaga),
    takeLatest(sendSurveyResults.type, sendSurveyResultsToQualtrics),
    takeLatest(removeAddOn.type, removeAddon),
    takeLatest(getAddOnOffers.type, fetchAddOnOffers),
    takeLatest(refetchAddOnOffers.type, refetchAddOnOffersSaga),
    takeLatest(getCurrentAddOns.type, fetchCurrentAddons),
    takeLatest(refetchCurrentAddOns.type, refetchCurrentAddOnsSaga),
  ]);
}
