import { Icon, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { ShoppingCart } from '@vst/beam-icons/icons';

import { Collapsible } from '@mfe/shared/components';

import { AddonCart } from './';
import { CartProps } from './types';
import { getPriceAndDiscount } from '../addons-grid/utils';
import styles from './styles.module.scss';

export const CollapsibleCart = (props: CartProps) => {
  const { t } = useTranslation('ShopAddons');
  const { price = undefined } = getPriceAndDiscount(props.addon);
  return (
    <Collapsible
      dataCy="collapsible-cart"
      classNames={{ summary: styles['collapsibleSummaryBackground'] }}
      summary={
        <div className={styles['collapsibleSummaryContent']}>
          <Icon
            data-cy="shopping-cart-icon"
            icon={ShoppingCart}
            size={24}
            color="gray_600"
          />
          {props.addon.is_grouped_product && !props.selectedEasyCareProduct ? (
            <Txt variant="tinyRegular" color="subtle">
              {t('groupedEasyCare.emptyCart')}
            </Txt>
          ) : (
            <>
              <Txt variant="smallRegular">{t('cart.monthly')}</Txt>
              <Txt variant="smallBold">
                {price === 0
                  ? t('cart.free')
                  : t('cart.monthlyPrice', { price })}
              </Txt>
            </>
          )}
        </div>
      }
      content={
        <div className={styles['collapsibleCartDetails']}>
          <AddonCart {...props} />
        </div>
      }
    />
  );
};
