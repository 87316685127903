import { ProductType } from '@mfe/shared/schema-types';
import { AddonPromotion } from './types';

export const getPriceAndDiscount = (addon: ProductType) => {
  const price = addon.prices?.[0]?.amount?.value ?? 0;
  const promotions: Array<AddonPromotion> = [];

  let amountTotal = 0;
  let totalPromotionDuration;

  if (price === undefined) {
    return {};
  }

  if (addon.discounts?.total_discounts?.amount?.value) {
    amountTotal += addon.discounts.total_discounts.amount.value;
  }

  //check both in total discounts and itemized discounts for duration
  if (addon.discounts?.total_discounts?.duration) {
    totalPromotionDuration = addon.discounts?.total_discounts?.duration;
  } else if (addon.discounts?.itemized_discounts?.length !== 0) {
    addon.discounts?.itemized_discounts?.forEach((discount) => {
      if (discount?.duration) {
        totalPromotionDuration = discount.duration;
      }
    });
  }

  if (
    addon.discounts?.itemized_discounts?.length &&
    addon.discounts?.itemized_discounts?.length > 0
  ) {
    addon.discounts?.itemized_discounts.forEach((discount) => {
      if (discount?.name && discount?.amount?.value) {
        promotions.push({
          name: discount?.name,
          amount: discount?.amount?.value,
        });
      }
    });
  }

  if (amountTotal !== 0) {
    return {
      oldPrice: price,
      price: price + amountTotal,
      promotions,
      totalPromotionDuration,
    };
  }

  return { price };
};
