import { Surface, IllustrativeIcon, Txt } from '@vst/beam';
import { Alert } from '@vst/beam-icons/illustrative-icons';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const AddonAemUnavailable = () => {
  const { t } = useTranslation('ShopAddons');

  return (
    <Surface py={'40px'} className={styles['addon-unavailable-container']}>
      <IllustrativeIcon
        icon={Alert}
        color={'teal_600'}
        style={{ height: '100px', width: '100px' }}
      />
      <Txt pt={'48px'} variant={'heading4'}>
        {t('aemFragmentError.title')}
      </Txt>
    </Surface>
  );
};
