import { Surface, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import { useScreenResolution } from '@mfe/shared/util';

import { CartAddonName } from './cart-addon-name';
import { TermsAndSubscribeButton } from './terms-and-subscribe';
import { CartProps } from './types';
import styles from './styles.module.scss';

export const EmptyCart = (props: CartProps) => {
  const { t } = useTranslation('ShopAddons');

  const { isLarge, isExtraLarge } = useScreenResolution();
  const isDesktop = isLarge || isExtraLarge;

  return (
    <div className={styles['cart']}>
      <Surface
        radius="16px"
        p={isDesktop ? '24px' : undefined}
        data-cy="cart-content"
      >
        <Txt variant="bodyLargeBold" color="subtle" mb="24px">
          {t('cart.title')}
        </Txt>
        <CartAddonName {...props.addon} />
        <div className={styles['empty-cart-price']}>
          <Txt variant="bodySmallRegular" color="subtle">
            {t('groupedEasyCare.cartSubscription')}
          </Txt>
          <Txt variant="tinyRegular" color="subtle">
            {t('groupedEasyCare.emptyCart')}
          </Txt>
        </div>
        <div className={styles['empty-cart-footer']}>
          <TermsAndSubscribeButton
            kind={props.addon.kind}
            submitButtonLabel={props.submitButtonLabel}
            handleClick={props.handleClick}
          />
        </div>
      </Surface>
    </div>
  );
};
