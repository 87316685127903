import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useNavigate from '@mfe/services/navigation';
import { ShopAddonsLink } from '@mfe/legacy/mv/utils/Navigation';
import { selectCurrentShopAddon } from '@mfe/to-be-migrated/redux/addOns';

export const useShopRedirectIfNoSelection = () => {
  const { replace } = useNavigate();
  const currentShopAddon = useSelector(selectCurrentShopAddon);

  useEffect(() => {
    if (!currentShopAddon) {
      replace(`/${ShopAddonsLink.ShopAddons}`);
    }
  }, [replace, currentShopAddon]);
};
